/**
 * @Author wangyubo
 * @Date 2024/5/23
 * @Path src\globalSettings.ts
 * @IDE globalSettings.ts
 * @Desc 导出一些全局配置
 **/

/** 统一时间格式  */
export const FORMAT_DATE = "YYYY.MM.DD HH:mm:ss"

export const FORMAT_DAY = "YYYY.MM.DD"

/** 前端最大值  */
export const Max_Num_Str = "9007199254740991"

/** 前端数量/次数 输入最大值  */
export const MAX_COUNT = 999999999999

/** 前端价格 输入的最大价格  */
export const MAX_PRICE = 99999999.9999

/** 附件上传的提示语  */
export const attachment_prompts = '（严禁在本互联网非涉密平台处理、传输国家秘密，请确认扫描、传输的文件不涉及国家秘密）'