import React from "react";
import styles from "./index.module.less";

interface PrimaryTitleProp {
  title: React.ReactNode;
  rightBtn?: React.ReactNode
}

/**
 * @Author wangyubo
 * @Date 2024/1/30
 * @Path src/components/PrimaryTitle
 * @IDE WebStorm
 * @Desc 带色块的标题
 * */
export default function PrimaryTitle(props: PrimaryTitleProp) {
  const {title, rightBtn} = props
  return <div className={styles.title_header}>
    <div className={styles.title_left}>
      <div className={styles.title_color_block}></div>
      <div className={styles.title_text}>{title}</div>
    </div>
    {
      rightBtn && <div className={styles.title_right}>{rightBtn}</div>
    }
  </div>;
}